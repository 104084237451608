<template>
  <v-sheet class="template" id="template">
    <v-row>
      <v-col md="8" class="my-auto">
        <v-layout>
          <v-col md="6" class="my-auto"
            ><h1 class="custom-header-blue-text m-0 text-truncate ml-1">
              {{ template?.template_name }}
            </h1>
          </v-col>
        </v-layout>
      </v-col>
      <v-col md="4" class="text-right">
        <v-btn
          :disabled="pageLoading || template.disable_edit"
          depressed
          tile
          class="mx-2 white--text"
          color="blue darken-4"
          @click="goBrodcastupdate(template.id)"
        >
          Edit
        </v-btn>
        <v-btn class="ml-2" :disabled="pageLoading" depressed tile v-on:click="goBack()">
          Back
        </v-btn>
      </v-col>
      <v-container fluid>
        <v-row>
          <v-col md="12">
            <table v-if="template" width="100%" class="detail-table mb-10">
              <tr>
                <th width="200">Template Category</th>
                <td>
                  <ValueTemplate
                    v-model="template.template_category"
                    title="template Category"
                  ></ValueTemplate>
                </td>
                <th width="200">Template Status</th>
                <td>
                  <Chip
                    small
                    style="text-transform: uppercase"
                    :color="getStatusBGColor(template?.status)"
                    :text="template?.status"
                  ></Chip>
                </td>
                <th width="200">Created At</th>
                <td>
                  <ValueTemplate
                    v-model="template.added_at"
                    date
                    title="template added at"
                  ></ValueTemplate>
                </td>
              </tr>
              <tr>
                <th width="200">Template Type</th>
                <td>
                  <ValueTemplate
                    v-model="template.template_type"
                    title="template type"
                  ></ValueTemplate>
                </td>
                <th width="200">Used Time</th>
                <td>
                  <ValueTemplate
                    v-if="template.template_type == 'whatsapp'"
                    v-model="template.used_in_whatsapp"
                    title="used in whatsapp"
                  ></ValueTemplate>
                  <ValueTemplate
                    v-if="template.template_type == 'sms'"
                    v-model="template.used_in_sms"
                    title="used in SMS"
                  ></ValueTemplate>
                  <ValueTemplate
                    v-if="template.template_type == 'email'"
                    v-model="template.used_in_email"
                    title="used in email"
                  ></ValueTemplate>
                </td>

                <th width="200">Modified At</th>
                <td>
                  <ValueTemplate
                    v-model="template.updated_at"
                    date
                    title="template updated at"
                  ></ValueTemplate>
                </td>
              </tr>
            </table>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12">
            <MobileFrame>
              <template v-slot:content>
                <div class="white p-4" style="border-radius: 1rem; background-color: white">
                  <v-img
                    v-if="template?.preview?.header_file"
                    contain
                    style="max-width: 100%; max-height: 150px"
                    height="150px;"
                    class="mb-2"
                    :src="template?.preview?.header_file"
                  ></v-img>
                  <div class="font-level-1-bold" style="font-weight: 600">
                    {{ template?.preview?.header }}
                  </div>
                  <div class="font-level-1 mt-2">
                    {{ template?.preview?.body }}
                  </div>
                  <div class="mt-4">
                    {{ template?.preview?.footer }}
                  </div>
                </div>
                <div
                  style="margin-top: 1px; border-radius: 1rem"
                  class="white px-4"
                  v-for="(item, index) in template?.preview?.buttons"
                  :key="index + template?.preview?.buttons.length"
                >
                  <v-col
                    style="font-weight: 600"
                    md="12"
                    class="font-level-2-bold cyan--text d-flex justify-center"
                  >
                    {{ item }}
                  </v-col>
                </div>
              </template>
            </MobileFrame>
          </v-col>
        </v-row>
      </v-container>

      <!-- <v-col md="12">
        <v-progress-linear
          indeterminate
          height="6"
          rounded
          v-if="pageLoading"
          class="position-absolute"
          color="blue darken-4"
        ></v-progress-linear>
      </v-col> -->
    </v-row>
  </v-sheet>
</template>

<script>
import ValueTemplate from "@/view/components/ValueTemplate";
import { GET_TEMPLATE } from "@/core/lib/marketing.lib";
import { SET_ERROR } from "@/core/services/store/common.module";
import MobileFrame from "@/view/module/marketing/MobileFrame.vue";
import Chip from "@/view/components/Chip";

export default {
  name: "template-detail",
  title: "Detail template",
  components: {
    ValueTemplate,
    MobileFrame,
    Chip,
  },
  data() {
    return {
      template: null,
      pageLoading: false,
      deleteDialog: false,
      deleteId: null,
      deleteText: null,
      broadcastHistoryList: [],
      currentPage: 1,
      customerSearch: null,
      generateSegmentDialog: false,
    };
  },
  methods: {
    goBrodcastupdate(id) {
      this.$router.push({
        name: "update-template",
        params: { id: id },
        query: { t: new Date().getTime() },
      });
    },
    async getTemplate() {
      const _this = this;
      try {
        const template = await GET_TEMPLATE(this.$route.params?.id);
        this.template = template;
        this.broadcastHistoryList = this.template.blast_list;
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      }
    },
  },
  async mounted() {
    if (this.$route.params?.id) {
      await this.getTemplate();
    }
  },
};
</script>
